import { useMemo } from 'react'

import identify from 'lib/utils/identify'
import { CurrencyPair } from 'types/currency-pair'

export const useCurrencyPairId = (currencyPair: CurrencyPair): string => {
  const currencyPairId = useMemo(
    () => identify.currencyPair(currencyPair),
    [currencyPair],
  )

  return currencyPairId
}
