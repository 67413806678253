import { Fragment, useCallback, useMemo, useState } from 'react'
import 'twin.macro'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

import Input from 'components/Input'
import { MarketDataBucket } from 'types/market-data-bucket'

import {
  SpreadCell,
  Container,
  QTYCellHeader,
  SpreadCellHeader,
  SellCellHeader,
  BuyCellHeader,
  BuyCell,
  SellCell,
  QTYCell,
} from './Grid.styles'

const opacityCoefficient = 0.04

export interface GridProps {
  buckets: MarketDataBucket[]
  setSizeBuckets?: (sizeBuckets: string[]) => void
}

export interface LadderEditState {
  rungOffset: number
  value: string
}

const Grid: React.FC<GridProps> = ({ buckets, setSizeBuckets }) => {
  const getBackgroundColor = (index: number): string =>
    `rgba(255, 255, 255, ${
      index * opacityCoefficient <= 0.16 ? index * opacityCoefficient : 0.16
    } )`

  const [ladderEditState, setLadderEditState] = useState<LadderEditState>()

  const onLadderQuantityChange = useCallback(
    (rungOffset: number) => (numberFormatValues: NumberFormatValues) => {
      setLadderEditState({ rungOffset, value: numberFormatValues.value })
    },
    [setLadderEditState],
  )

  const maybeEditedBuckets = useMemo(() => {
    if (!ladderEditState) return buckets

    return buckets.map((bucket, i) => ({
      ...bucket,
      ...(i === ladderEditState.rungOffset
        ? { quantity: ladderEditState.value }
        : null),
    }))
  }, [buckets, ladderEditState])

  const submit = useCallback(() => {
    if (!ladderEditState) {
      return
    }

    setSizeBuckets(maybeEditedBuckets.map((bucket) => bucket.quantity))

    setLadderEditState(null)
  }, [maybeEditedBuckets, ladderEditState, setSizeBuckets, setLadderEditState])

  return (
    <Container>
      <QTYCellHeader>QTY</QTYCellHeader>
      <SellCellHeader>Sell</SellCellHeader>
      <SpreadCellHeader>Spread</SpreadCellHeader>
      <BuyCellHeader>Buy</BuyCellHeader>
      {maybeEditedBuckets?.map((bucket, index) => (
        <Fragment key={index}>
          <QTYCell
            style={{
              background: getBackgroundColor(index),
            }}
          >
            <NumberFormat
              thousandSeparator={true}
              customInput={Input}
              hiddenStyle
              value={bucket.quantity}
              onValueChange={onLadderQuantityChange(index)}
              onBlur={submit}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submit()
                }
              }}
              tw="h-[23px] w-full"
            />
          </QTYCell>
          <SellCell
            style={{
              background: getBackgroundColor(index),
            }}
          >
            {bucket.sellPrice}
          </SellCell>
          <SpreadCell
            style={{
              background: getBackgroundColor(index),
            }}
          >
            {bucket.spread?.toFixed(2)}
          </SpreadCell>
          <BuyCell
            style={{
              background: getBackgroundColor(index),
            }}
          >
            {bucket.buyPrice}
          </BuyCell>
        </Fragment>
      ))}
    </Container>
  )
}

export default Grid
