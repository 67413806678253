import 'twin.macro'

import RFQ from 'assets/icons/rfq.svg'
import CurrencyPairHeader from 'components/CurrencyPairHeader'
import IconButton from 'components/IconButton'
import Select from 'components/Select'
import { SelectData } from 'components/Select/Select'
import { OrderTypeLabels } from 'lib/constants'
import { OrderType } from 'lib/interfaces'
import { Currency } from 'types/currency'

import { Toolbar, Wrapper } from './Header.styles'

const selectData: SelectData[] = Object.entries(OrderTypeLabels).map(
  ([key, value]: [key: OrderType, value: string]) => ({
    label: value,
    value: key,
  }),
)

export interface HeaderProps {
  baseCurrency: Currency
  quotedCurrency: Currency
  orderStatus?: OrderType
  setBaseCurrency: (value: Currency) => void
  setQuotedCurrency: (value: Currency) => void
  onRFQClick?: () => void
  onOrderChange: (value: OrderType) => void
}

export const Header: React.FC<HeaderProps> = ({
  baseCurrency: defaultBaseCurrency,
  quotedCurrency: defaultQuotedCurrency,
  orderStatus,
  setBaseCurrency,
  setQuotedCurrency,
  onRFQClick,
  onOrderChange,
}) => {
  const handleOnOrderChange = (e: { value: SelectData }) => {
    const value = e.value.value as OrderType
    onOrderChange(value)
  }

  return (
    <Wrapper>
      <CurrencyPairHeader
        quotedCurrency={defaultQuotedCurrency}
        baseCurrency={defaultBaseCurrency}
        setBaseCurrency={setBaseCurrency}
        setQuotedCurrency={setQuotedCurrency}
      />
      <Toolbar>
        <IconButton size="small" onClick={onRFQClick} aria-label="rfq">
          <RFQ />
        </IconButton>
        <Select
          data={selectData}
          value={orderStatus}
          onChange={handleOnOrderChange}
        />
      </Toolbar>
    </Wrapper>
  )
}
