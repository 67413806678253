import React, { useEffect, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import StreamingTile from 'components/StreamingTile'
import { useCurrencyPairId } from 'hooks/useCurrencyPairId'
import {
  replaceBaseCurrency,
  replaceQuotedCurrency,
  replaceAmount,
  replaceNormalSize,
  replaceSizeBuckets,
} from 'lib/reducers/streamingTileLocalSliverReducers'
import { clampSizeBuckets } from 'lib/utils'
import store from 'store'
import { Currency } from 'types/currency'
import { GroupPosition } from 'types/group'

import { Container } from './StreamingTileSlotConnector.styles'

export type StreamingTileSlotConnectorProps = GroupPosition

const StreamingTileSlotConnector: React.FC<GroupPosition> = (groupPosition) => {
  const [streamingTileLocalSliver, setStreamingTileLocalSliver] =
    useRecoilState(store.streamingTiles.sliverByPosition(groupPosition))
  const subscriptionParams = useRecoilValue(
    store.streamingTiles.monitorParamsByPosition(groupPosition),
  )

  const topMdsSelectedRung = useRecoilValue(
    store.streamingTiles.topMdsSelectedRung({
      topMonId: subscriptionParams?.top.monId,
      volMonId: subscriptionParams?.vol.monId,
      groupPosition,
    }),
  )
  const volMarketDataSnapshot = useRecoilValue(
    store.marketDataSnapshots(subscriptionParams?.vol.monId),
  )

  const [
    setStreamingTileBaseCurrency,
    setStreamingTileQuotedCurrency,
    setAmount,
    setNormalSize,
    setSizeBuckets,
  ] = useMemo(
    () => [
      (baseCurrency: Currency) => {
        setStreamingTileLocalSliver(replaceBaseCurrency(baseCurrency))
      },
      (quotedCurrency: Currency) => {
        setStreamingTileLocalSliver(replaceQuotedCurrency(quotedCurrency))
      },
      (amount: string) => {
        setStreamingTileLocalSliver(replaceAmount(amount))
      },
      (normalSize: string) => {
        setStreamingTileLocalSliver(replaceNormalSize(normalSize))
      },
      (sizeBuckets: string[]) => {
        setStreamingTileLocalSliver(replaceSizeBuckets(sizeBuckets))
      },
    ],
    [setStreamingTileLocalSliver],
  )

  const { currencyPair, amount, sizeBuckets } = streamingTileLocalSliver ?? {}

  const currencyPairId = useCurrencyPairId(currencyPair)
  const normalSize = useRecoilValue(
    store.customerSecurities.normalSizeBySymbol(currencyPairId),
  )
  const canonicalSizeBuckets = useRecoilValue(
    store.customerSecurities.canonicalSizeBucketsBySymbol(currencyPairId),
  )

  useEffect(() => {
    if (canonicalSizeBuckets && sizeBuckets?.length === 0) {
      setSizeBuckets(canonicalSizeBuckets)
    }
  }, [canonicalSizeBuckets, sizeBuckets, setSizeBuckets])

  useEffect(() => {
    if (normalSize) {
      setNormalSize(normalSize)
    }
  }, [normalSize, setNormalSize])

  const clampedSizeBuckets = useMemo(
    () => (sizeBuckets ? clampSizeBuckets(sizeBuckets, normalSize) : null),
    [sizeBuckets, normalSize],
  )

  return (
    <Container>
      {streamingTileLocalSliver && (
        <StreamingTile
          currencyPair={currencyPair}
          topMarketDataSnapshot={topMdsSelectedRung}
          volMarketDataSnapshot={volMarketDataSnapshot}
          amount={amount}
          setAmount={setAmount}
          sizeBuckets={clampedSizeBuckets}
          setSizeBuckets={setSizeBuckets}
          onBaseCurrencyChange={setStreamingTileBaseCurrency}
          onQuotedCurrencyChange={setStreamingTileQuotedCurrency}
        />
      )}
    </Container>
  )
}

export default StreamingTileSlotConnector
