import {
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import tw from 'twin.macro'

import Calendar from 'assets/icons/calendar.svg'
import Close from 'assets/icons/close.svg'
import DatePicker from 'components/InputRow/DatePicker'
import { useHandleClickOutside } from 'hooks/useHandleClickOutside'
import { parseDateFromDateInputRowFormat } from 'lib/utils/date'

import { InputRow } from './InputRow'

const OptionalContainer = tw.div`text-gray-500`
const Container = tw.div`relative w-full`

const parseStringFromDate = (date?: Date) => {
  if (!date) return ''

  const year = date.getFullYear().toString().substring(2)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  return `${year}/${month}/${day} - ${hours}:${minutes}`
}

export interface DateInputRowProps {
  type: 'start' | 'stop'
  date: Date | null
  setDate: Dispatch<SetStateAction<Date>>
  onClose?(): void
}

export const DateInputRow: FC<DateInputRowProps> = ({
  type,
  date,
  setDate,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)
  const [dateString, setDateString] = useState<string>()
  const ref = useRef(null)
  const placeholder = type === 'start' ? 'Start time' : 'End time'

  useEffect(
    () => setDateString(parseStringFromDate(date)),
    [setDateString, date],
  )

  const handleToggleDatePicker = useCallback(
    () => setOpenDatePicker((prev) => !prev),
    [setOpenDatePicker],
  )

  const handleClickOutside = useCallback(() => {
    if (dateString.length === 0) return setDate(null)
    const parsedDateString = parseStringFromDate(date)
    if (dateString === parsedDateString) return

    const newDate = parseDateFromDateInputRowFormat(dateString)
    if (newDate) setDate(newDate)
    else setDateString(parsedDateString)
  }, [dateString, setDateString, setDate, date])

  useHandleClickOutside(ref, handleClickOutside)

  return (
    <Container ref={ref}>
      <InputRow
        value={dateString}
        onChange={setDateString}
        type="text"
        placeholder={placeholder}
      >
        <OptionalContainer>Optional</OptionalContainer>
        <button onClick={() => setOpenDatePicker((prev) => !prev)}>
          {!openDatePicker && <Calendar tw="w-[16px] h-[16px]" />}
          {openDatePicker && <Close tw="fill-white w-[16px] h-[16px]" />}
        </button>
      </InputRow>
      {openDatePicker && (
        <DatePicker
          onClose={handleToggleDatePicker}
          date={date}
          setDate={setDate}
        />
      )}
    </Container>
  )
}
