import { FC, ChangeEvent, useState, useEffect, useMemo } from 'react'
import tw from 'twin.macro'

import Input from 'components/Input'
import { getLocalTimeZone } from 'lib/utils/date'

const Container = tw.div``
const InputContainer = tw.div`flex items-center justify-center gap-x-0.5 text-gray-200`
const QuickTimeContainer = tw.div`flex gap-3 justify-evenly my-2  text-[12px] border-b-[1px] border-gray-200 text-gray-200 font-bahnschrift`
const QuickTimeItem = tw.button``
const TimeZone = tw.div`font-bahnschrift font-semibold text-[12px] text-gray-200`

export interface TimePickerProps {
  setMinutes(minutes: number): void
  setHours(hours: number): void
  date: Date
}

export const TimePicker: FC<TimePickerProps> = ({
  setMinutes,
  setHours,
  date,
}) => {
  const [localMinutes, setLocalMinutes] = useState<string>(
    date.getMinutes().toString(),
  )
  const [localHours, setLocalHours] = useState<string>(
    date.getHours().toString(),
  )

  useEffect(() => {
    setLocalHours(date.getHours().toString())
    setLocalMinutes(date.getMinutes().toString())
  }, [date])

  const [
    setEOD,
    addMinutes,
    addHours,
    handleHourChange,
    handleMinuteChange,
    tz,
  ] = useMemo(() => {
    const setEOD = () => {
      setHours(23)
      setMinutes(59)
    }

    const addMinutes = (n: number) => {
      setMinutes(date.getMinutes() + n)
    }

    const addHours = (n: number) => {
      setHours(date.getHours() + n)
    }

    const handleHourChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setLocalHours(value)

      if (value.length > 0) {
        const parsed = parseInt(value)
        if (parsed <= 23) {
          setHours(parsed)
        }
      }
    }

    const handleMinuteChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setLocalMinutes(value)

      if (value.length > 0) {
        const parsed = parseInt(value)
        if (parsed <= 59) {
          setMinutes(parsed)
        }
      }
    }

    const tz = getLocalTimeZone()
    return [
      setEOD,
      addMinutes,
      addHours,
      handleHourChange,
      handleMinuteChange,
      tz,
    ]
  }, [setHours, setMinutes, date])

  return (
    <Container>
      <QuickTimeContainer>
        {[5, 10, 30, 60].map((n) => (
          <QuickTimeItem key={n} onClick={() => addMinutes(n)}>
            {n}m
          </QuickTimeItem>
        ))}
        {[2, 4].map((n) => (
          <QuickTimeItem key={n} onClick={() => addHours(n)}>
            {n}h
          </QuickTimeItem>
        ))}
        <QuickTimeItem onClick={setEOD}>EOD</QuickTimeItem>
      </QuickTimeContainer>
      <InputContainer>
        <Input
          type="number"
          min={0}
          max={24}
          value={localHours}
          onChange={handleHourChange}
          tw="w-[90px]"
          data-testid="hour-input"
        />
        <div>:</div>
        <Input
          type="number"
          min={0}
          max={60}
          value={localMinutes}
          onChange={handleMinuteChange}
          tw="w-[90px]"
          data-testid="minute-input"
        />
        <TimeZone>
          GMT {tz.sign} {tz.offset}
        </TimeZone>
      </InputContainer>
    </Container>
  )
}
