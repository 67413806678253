import { useMemo } from 'react'

import { calculateSpread } from 'lib/utils'
import { MarketDataSnapshot } from 'types/api/market-data-snapshot'

const RESET_PRICES = ['-', '-', '-']

export const useTopRungPriceAndSpread = (
  marketDataSnapshot: MarketDataSnapshot,
) => {
  const [sellPrice, buyPrice, spread] = useMemo(() => {
    if (!marketDataSnapshot) {
      return RESET_PRICES
    }

    const sellPrice = marketDataSnapshot.Bids?.[0]?.Price
    const buyPrice = marketDataSnapshot.Offers?.[0]?.Price

    if (!sellPrice || !buyPrice) {
      return RESET_PRICES
    }

    const spread = calculateSpread(Number(buyPrice), Number(sellPrice)).toFixed(
      2,
    )

    return [sellPrice, buyPrice, spread]
  }, [marketDataSnapshot])

  return {
    buyPrice,
    sellPrice,
    spread,
  }
}
