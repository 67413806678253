import { useMemo } from 'react'

import { calculateSpread } from 'lib/utils'
import { MarketDataSnapshot } from 'types/api/market-data-snapshot'
import { MarketDataBucket } from 'types/market-data-bucket'

export const useZippedBidsAndOffers = (
  marketDataSnapshot: MarketDataSnapshot,
  sizeBuckets: string[],
) => {
  const bucketsFromMessage: MarketDataBucket[] = useMemo(() => {
    if (!marketDataSnapshot) return null

    const result = []

    const { Bids, Offers } = marketDataSnapshot

    for (let i = 0; i < sizeBuckets.length; i++) {
      const bid = Bids[i]
      const offer = Offers[i]

      const quantity = sizeBuckets[i]
      const buyPrice = offer ? offer.Price : null
      const sellPrice = bid ? bid.Price : null

      const index: MarketDataBucket = {
        quantity,
        sellPrice,
        buyPrice,
        spread:
          buyPrice && sellPrice
            ? calculateSpread(Number(buyPrice), Number(sellPrice))
            : null,
      }

      result.push(index)
    }

    return result
  }, [marketDataSnapshot, sizeBuckets])

  return bucketsFromMessage
}
