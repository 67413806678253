import { useMemo } from 'react'

import useBalance from 'hooks/useBalance'
import { BalanceResponse } from 'types/api/balance'
import { CurrencyPair } from 'types/currency-pair'

type CurrencyPairBalances = [string, string] // Talos decimals

const DEFAULT_BALANCE = '0.00'
const DEFAULTS = [DEFAULT_BALANCE, DEFAULT_BALANCE] as CurrencyPairBalances

const getAmountPreferAvailable = (balance: BalanceResponse): string =>
  balance?.AvailableAmount ?? balance?.Amount ?? DEFAULT_BALANCE

export const useCurrencyPairBalances = (
  currencyPair: CurrencyPair,
): CurrencyPairBalances => {
  const balances = useBalance(currencyPair)

  return useMemo(() => {
    if (!balances?.length) {
      return DEFAULTS
    }

    const [baseCurrency, quotedCurrency] = currencyPair ?? []
    const { Symbol: baseSymbol } = baseCurrency ?? {}
    const { Symbol: quotedSymbol } = quotedCurrency ?? {}

    const baseCurrencyBalance = balances.find(
      (balanceResponse) => baseSymbol === balanceResponse?.Currency,
    )
    const quotedCurrencyBalance = balances.find(
      (balanceResponse) => quotedSymbol === balanceResponse?.Currency,
    )

    return [
      getAmountPreferAvailable(baseCurrencyBalance),
      getAmountPreferAvailable(quotedCurrencyBalance),
    ]
  }, [balances, currencyPair])
}
