import { useEffect, RefObject } from 'react'

export const useHandleClickOutside = (
  ref: RefObject<HTMLElement>,
  handler?: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        handler && handler()
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [handler, ref])
}
