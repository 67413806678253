/**
 * This is intended for parsing date inputs from a string in the form of YY/MM/DD - HH:MM
 * Year should be only 2 digits
 * Month is expected to be in [1, 12]
 * Days is expected to be in [1, 31]
 */
export const dateIsInPast = (date: Date) => {
  const currentDate = new Date()
  const diff = date.getTime() - currentDate.getTime()
  return diff < 0
}

export const getMaximumDayForMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate()
}

export const parseDateFromDateInputRowFormat = (dateString: string) => {
  const datetime = dateString.split('-')
  const [year, month, day] = datetime[0]
    .split('/')
    .map(Number)
    .filter((e) => !isNaN(e))
  if (day && month && year) {
    if (year < 100 && month <= 12) {
      const maxDay = getMaximumDayForMonth(year, month)

      if (day > maxDay) return

      const newDate = new Date(year + 2000, month - 1, day)
      if (dateIsInPast(newDate)) return

      if (datetime.length === 2) {
        const [hours, minutes] = datetime[1]
          .split(':')
          .map(Number)
          .filter((e) => !isNaN(e))
        if (hours && hours < 24) {
          newDate.setHours(hours)
        }

        if (minutes && minutes < 60) {
          newDate.setMinutes(minutes)
        }
      }
      return newDate
    }
  }

  return null
}

export const getLocalTimeZone = () => {
  const offset = new Date().getTimezoneOffset() / 60
  return {
    offset: Math.abs(offset),
    sign: offset < 0 ? '-' : '+',
  }
}
